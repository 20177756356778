<script setup lang="ts">
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
import Button from './button.vue';
import { onClickOutside } from '@vueuse/core';
import { ref } from 'vue';
import GenericError from '../user-interface/generic-error.vue';

const emits = defineEmits(['select', 'next', 'previous']);
let display: any = reactive({ pages: [] });
const goToPageNo: globalThis.Ref<number> = ref(0);
const showGoTo: globalThis.Ref<boolean> = ref(false);
const goToDiv = ref(null);
const goToInput = ref(null);
const breakpoint = useBreakpoint();
const displayError: globalThis.Ref<boolean> = ref(false);

interface IPaginationProps {
  currentPage: number;
  totalPages: number;
  showingStart: number;
  showingEnd: number;
  totalResults: number;
}

const props = withDefaults(defineProps<IPaginationProps>(), {
  currentPage: 2,
  totalPages: 5,
});

const isManyPages = computed(() => {
  return props.totalPages > 1;
});

//calculate the array of pages that must be displayed based on current page and total pages
function calculatePages() {
  const currentPage = props.currentPage;
  const totalPages = props.totalPages;
  const start = 1;

  if (!breakpoint.sm.value) {
    if (props.totalPages < 8) {
      display.pages = [];
      for (let index = 0; index < props.totalPages; index++) {
        display.pages.push(index + 1);
      }
    } else {
      if (currentPage < start + 2 || currentPage + 2 > totalPages) {
        display.pages = [
          1,
          2,
          3,
          '...',
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else if (currentPage < start + 4) {
        display.pages = [1, 2, 3, 4, 5, '...', totalPages];
      } else if (currentPage + 4 > totalPages) {
        display.pages = [
          1,
          '...',
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        display.pages = [
          1,
          '...',
          currentPage - 1,
          currentPage,
          currentPage + 1,
          '...',
          totalPages,
        ];
      }
    }
  }
  //Mobile Page calculation
  else {
    if (props.totalPages < 5) {
      display.pages = [];
      for (let index = 0; index < props.totalPages; index++) {
        display.pages.push(index + 1);
      }
    } else {
      if (currentPage === start || currentPage === totalPages) {
        display.pages = [1, 2, '...', totalPages - 1, totalPages];
      } else if (currentPage < start + 3) {
        display.pages = [1, 2, 3, '...', totalPages];
      } else if (currentPage + 3 > totalPages) {
        display.pages = [1, '...', totalPages - 2, totalPages - 1, totalPages];
      } else {
        display.pages = [1, '...', currentPage, '...', totalPages];
      }
    }
  }
}

//if left ellipses clicked populate GoTo with currentPage-1 and vice versa for right ellipses
function calculateGoTo(index: number) {
  showGoTo.value = true;
  const currentPageIndex: any = Object.keys(display.pages).find(
    (key) => display.pages[key] === props.currentPage
  );

  if (currentPageIndex < index) {
    goToPageNo.value = display.pages[index - 1] + 1;
  } else {
    goToPageNo.value = display.pages[index + 1] - 1;
  }
  const el = document.getElementById('goToInput');
  el?.focus();
}

function select(pageNo: number) {
  if (pageNo > 0 && pageNo < props.totalPages + 1) {
    displayError.value = false;
    emits('select', pageNo);
  } else {
    displayError.value = true;
  }
}

onClickOutside(goToDiv, (event) => {
  showGoTo.value = false;
  displayError.value = false;
});

onMounted(() => {
  calculatePages();
});

watch(
  () => [props.currentPage, props.totalPages],
  (pageNo) => {
    calculatePages();
  }
);
</script>

<template>
  <div v-if="isManyPages" class="flex flex-col items-center flex-wrap">
    <div
      class="justify-center dark:text-white text-dark-600 flex flex-row mb-2 text-base"
    >
      <span class="my-0">
        {{ $t('showing') }}
        <b>{{ showingStart }}</b>
        {{ $t('to') }}
        <b>{{ showingEnd }}</b>
        {{ $t('of') }}
        <b>{{ totalResults }}</b>
        {{ $t('jpc-results') }}
      </span>
    </div>

    <div
      class="flex flex-row border-1 std-border rounded-md w-fit cursor-pointer mx-auto"
    >
      <div
        class="flex-center std-border-color text-base-priority blue-gradient-hover w-10 h-10 hover:text-white bg-light-50 dark:bg-dark-700 rounded-l-md"
        @click="$emit('previous')"
      >
        <ChevronLeftIcon
          class="w-6 dark:text-white my-2 text-dark-800 hover:text-white"
        />
      </div>

      <div
        v-for="(page, index) in display.pages"
        class="flex-center std-border-color border-l blue-gradient-hover hover:text-white w-10 h-10 bg-light-50 dark:bg-dark-700"
        :class="[page === currentPage ? 'blue-gradient' : '']"
        @click="!isNaN(page) ? $emit('select', page) : calculateGoTo(index)"
      >
        <p
          class="justify-center text-base font-normal my-0 py-0"
          :class="[page === currentPage ? 'text-white font-bold' : '']"
        >
          {{ page }}
        </p>
      </div>

      <div
        class="border-l flex-center std-border-color text-base-priority blue-gradient-hover w-10 h-10 hover:text-white bg-light-50 dark:bg-dark-700 rounded-r-md"
        @click="$emit('next')"
      >
        <ChevronRightIcon class="w-6 my-2" />
      </div>
    </div>
    <div v-show="showGoTo">
      <div
        ref="goToDiv"
        class="flex gap-2 mx-auto my-1 rounded-md std-border w-fit py-1 px-2 bg-light-50 dark:bg-dark-700"
      >
        <div class="flex flex-col b-1 b-secondary-blue-800 rounded-md">
          <p class="text-xs text-base-priority">
            {{ $t('Go-to-page') }}
          </p>
          <input
            id="goToInput"
            ref="goToInput"
            v-model.number="goToPageNo"
            class="bg-light-50 dark:bg-dark-700 text-base-priority"
            autofocus
            type="number"
            @keyup.enter="select(goToPageNo)"
          />
        </div>
        <Button padding="wide" @click="select(goToPageNo)">
          {{ $t('go') }}
        </Button>
      </div>
      <GenericError v-if="displayError" state="danger" class="!h-fit">
        {{ $t('invalid-page-number') }}
      </GenericError>
    </div>
  </div>
</template>

<style scoped lang="scss">
input:focus-within {
  caret-color: var(--primary-bluebase);
}
</style>

<style>
/* Remove up down arrow from input type number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.blue-gradient-border-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.blue-gradient-border-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
</style>
